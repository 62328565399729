<!-- 用户详情 -->
<template>
  <div class="container">
    <a-page-header :title="title" @back="() => $router.go(-1)" />
    <div class="main-content">
      <div class="body" v-if="info">
        <div class="body-left">
          <div class="user">
            <div class="avatar">
              <img class="img" :src="info.avatar" />
            </div>
            <div class="name">{{ info.nickname }}</div>
            <a-tag color="green">
              用户状态：正常
            </a-tag>
          </div>
        </div>

        <div class="body-right">
          <div class="text-content">
            <div class="content-item">
              <div class="content-item-header">
                <div class="title">用户信息</div>
              </div>
              <div class="content-sub-title">概述</div>

              <div class="ul ul-1">
                <div class="li">
                  <span class="label">用户昵称：</span>
                  <span>{{ info.nickname }}</span>
                </div>
                <div class="li">
                  <span class="label">注册手机号：</span>
                  <span>{{ info.mobile }}</span>
                </div>
              </div>
              <div class="ul">
                <div class="li">
                  <span class="label">用户性别：</span>
                </div>
                <div class="li">
                  <span class="label">地区：</span>
                  <span>{{ info.city }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="order-log">
        <div class="main-content-header">
          <div class="item">
            <div class="title">下单记录</div>
          </div>
          <div class="item">累积下单次数：{{ pagination.total }}次</div>
        </div>
        <div class="table">
          <a-table
            class="order-tabel"
            :columns="columns1"
            :data-source="orderLog"
            :pagination="pagination"
            :row-key="(record) => record.id"
            @change="handlePaginationChange"
          >
            <template slot="status" slot-scope="record">
              {{ $orderStatusText[record.status] }}
            </template>
            <template slot="price" slot-scope="record">
              <a-statistic prefix="￥" :value="record.price" />
            </template>
            <template slot="action" slot-scope="record">
              <a-button type="link">
                <router-link
                  :to="{
                    path: '/orderItemInfo',
                    query: { id: record.id }
                  }"
                >
                  更多详情
                </router-link>
              </a-button>
            </template>
          </a-table>
        </div>
      </div>
      <div class="address-log">
        <div class="main-content-header">
          <div class="item">
            <div class="title">地址记录</div>
          </div>
        </div>
        <div class="table">
          <a-table
            class="order-tabel"
            :columns="columns2"
            :data-source="addressLog"
            :pagination="false"
            :row-key="(record) => record.id"
          >
            <template slot="status" slot-scope="record">
              {{ $orderStatusText[record.status] }}
            </template>
            <template slot="price" slot-scope="record">
              <a-statistic prefix="￥" :value="record.price" />
            </template>
            <template slot="action" slot-scope="record">
              <a-button type="link">
                <router-link
                  :to="{
                    path: '/orderItemInfo',
                    query: { id: record.id }
                  }"
                >
                  更多详情
                </router-link>
              </a-button>
            </template>
          </a-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
let userId = ''
let btype =""
export default {
  data() {
    return {
      title: '',
      info: null,
      addressLog: [],
      orderLog: [],
      statuText: {
        1: '正常',
        0: '禁用'
      },
      columns1: [
        {
          title: '序号',
          width: 70,
          customRender: (text, record, index) => index + 1
        },
        {
          title: '订单类型',
          width: 266,
          dataIndex: 'sername'
        },
        {
          title: '状态',
          width: 200,
          scopedSlots: {
            customRender: 'status'
          }
        },
        {
          title: '订单总金额',
          width: 244,
          scopedSlots: {
            customRender: 'price'
          }
        },
        {
          title: '操作',
          scopedSlots: {
            customRender: 'action'
          }
        }
      ],
      columns2: [
        {
          title: '序号',
          width: 74,
          customRender: (text, record, index) => index + 1
        },
        {
          title: '联系人',
          width: 120,
          dataIndex: 'name'
        },
        {
          title: '手机号',
          width: 130,
          dataIndex: 'mobile'
        },
        {
          title: '上门地址',
          dataIndex: 'address'
        },
        {
          title: '门牌号',
          dataIndex: 'housenumber',
          width: 206
        }
      ],
      pagination: {
        current: 1,
        total: 0,
        defaultPageSize: 3
      }
    }
  },
  computed: {
    ...mapState(['$orderStatusText'])
  },
  created() {
    this.title = this.$getPageTitle()
    userId = this.$route.query.id
    btype = this.$route.query.btype
    console.log("btype",btype)
    this.getUsersInfo()
    this.getUsersOrder()
  },
  methods: {
    getUsersInfo() {
      const data = {
        id: userId
      }
      this.$axios.getUsersInfo(data).then((res) => {
        const d = res.data.data
        this.info = d.info
        this.addressLog = d.addressLog
      })
    },
    handlePaginationChange(e) {
      this.pagination.current = e.current
      this.getUsersOrder()
    },
    getUsersOrder() {
      // 获取用户订单列表
      const data = {
        id: userId,
        page: this.pagination.current,
        limit: 3,
        btype:btype 
      }
      this.$axios.getUsersOrder(data).then((res) => {
        const d = res.data.data
        this.orderLog = d.data
        this.pagination.total = d.count
      })
    }
  }
}
</script>
<style scoped>
.body {
  display: flex;
  justify-content: space-between;
}

.body-left,
.text-content,
.id-card-content {
  background-color: #fff;
  border-radius: 2px;
}

.body-left {
  width: 238px;
  padding: 32px;
}

.user {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-right: 1px solid rgba(0, 0, 0, 0.15);
}

.avatar {
  width: 80px;
  height: 80px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  padding: 8px;
  border-radius: 3px;
  margin: 0 auto;
}

.name {
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  line-height: 16px;
  margin: 8px 0;
}

.body-right {
  flex: 1;
}

.text-content,
.id-card-content {
  padding: 20px 32px 24px 32px;
}

.id-card-content {
  margin-top: 16px;
}

.content-item-flex {
  display: flex;
  align-items: center;
}

.content-item-header .title {
  color: #333;
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
  margin-bottom: 24px;
}

.content-sub-title {
  color: #333;
  font-size: 14px;
  font-weight: bold;
}

.ul-1 {
  margin-top: 16px;
}

.ul:not(:last-child) {
  margin-bottom: 16px;
}

.ul .li {
  width: 240px;
  display: inline-block;
  line-height: 22px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ul .li:not(:last-child) {
  margin-right: 46px;
}

.ul .label {
  font-weight: bold;
}

.order-log,
.address-log {
  margin-top: 16px;
  background-color: #fff;
}

.table {
  padding: 32px;
}
</style>
